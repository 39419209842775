<template>
  <div class="bg-white p-4 rounded-md shadow-md">
    <h2 class="text-2xl font-bold mb-5">Reset Password</h2>
    <span
      v-if="message !== ''"
      :class="`mb-3 block text-xs font-semibold ${
        error ? 'text-red-500' : 'text-green-500'
      }`"
      >{{ message }}</span
    >
    <a-form-model
      ref="signinForm"
      :rules="rules"
      layout="vertical"
      :model="form"
    >
      <a-form-model-item label="Email" prop="email">
        <a-input
          v-model="form.email"
          type="email"
          :disabled="signing_in"
          placeholder="Email"
        />
      </a-form-model-item>
      <a-form-model-item>
        <a-button :loading="signing_in" type="primary" @click="onSubmit">
          Reset
        </a-button>
      </a-form-model-item>
      <p>
        <router-link to="/signin">Back</router-link>
      </p>
      <p>
        I don't have an account.
        <router-link to="/signup">Sign Up </router-link>here.
      </p>
    </a-form-model>
  </div>
</template>
<script>
import authApi from '../api/auth'
export default {
  name: 'SignIn',
  layout: 'auth',
  data() {
    return {
      error: false,
      message: '',
      signing_in: false,
      form: {
        email: '',
      },
      rules: {
        email: [
          {
            pattern:
              /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
            message: 'Enter a valid email address',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  head: {
    title: 'Reset Password | Legal Naija',
  },
  created() {
    this.form.email = this.$route.query.email
  },
  methods: {
    async recoverPassword() {
      this.message = ''
      this.signing_in = true
      const req = await authApi(this.axios).recoverPassword({
        email: this.form.email,
      })
      if (req.error) {
        this.error = true
        this.message = req.message
        this.signing_in = false
        return
      }
      this.error = false
      this.message = req.message + 'You will be redirected to login'
      this.signing_in = false
      setTimeout(() => {
        this.$router.push('/signin')
      }, 4000)
    },
    onSubmit() {
      this.$refs.signinForm.validate((valid) => {
        if (valid) {
          this.recoverPassword()
        } else {
          return false
        }
      })
    },
  },
}
</script>
